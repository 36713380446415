import Timeout from 'smart-timeout'

class MobileActiveButtons {
  constructor() {
    this.bound = {
      cancelPendingNavigation: this.cancelPendingNavigation.bind(this)
    }

    this.initialize()
  }

  initialize() {
    const $els = document.querySelectorAll('a[data-mobile-active]')
    for (let i = 0; i < $els.length; i++) {
      $els[i].addEventListener('touchstart', this.onTouch.bind(this))
    }
  }

  onTouch(event) {
    this.$activeEl = event.target
    const url = this.$activeEl.getAttribute('href')
    const activeClass = this.$activeEl.getAttribute('data-mobile-active')
    const isMail = url.indexOf('mailto:') !== -1

    this.$activeEl.classList.add(activeClass)

    if (isMail) {
      return
    }

    event.preventDefault()

    Timeout.set('navigate', $el => {
      if (!$el.hasAttribute('data-mobile-active-keep')) {
       $el.classList.remove(activeClass)
      }
      window.location.href = url
    }, 350, this.$activeEl)

    window.addEventListener('touchmove', this.bound.cancelPendingNavigation, false)
  }

  cancelPendingNavigation() {
    window.removeEventListener('touchmove', this.bound.cancelPendingNavigation, false)
    Timeout.clear('navigate')
    if (this.$activeEl) {
      const activeClass = this.$activeEl.getAttribute('data-mobile-active')
      this.$activeEl.classList.remove(activeClass)
      this.$activeEl = null
    }
  }
}

export default MobileActiveButtons
