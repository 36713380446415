import './polyfills'
import 'lazysizes'
import 'lazysizes/plugins/respimg/ls.respimg'

import Vue from 'vue'

import './app.css'
import AnimatedEntry from './lib/animated-entry.io'
import MobileActiveButtons from './lib/mobile-active-buttons'
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion'

Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('SlideUpDown', () => import('vue-slide-up-down'))

Vue.component('Navigation', () => import('./components/Navigation.vue'))
Vue.component('BurgerButton', () => import('./components/BurgerButton.vue'))
Vue.component('Intro', () => import('./components/Intro.vue'))
Vue.component('Unfolder', () => import('./components/Unfolder.vue'))
Vue.component('Partners', () => import('./components/Partners.vue'))
Vue.component('Waterfall', () => import('./components/Waterfall.vue'))
Vue.component('Elevator', () => import('./components/Elevator.vue'))
Vue.component('VideoPlayer', () => import('./components/VideoPlayer.vue'))
Vue.component('ToggleIcon', () => import('./components/ToggleIcon.vue'))

Vue.config.productionTip = false

const vm = new Vue({
  delimiters: ['${', '}'],
})

vm.$mount('#nfld-root')

// ToDo: Wait until mounted
setTimeout(() => {
  new AnimatedEntry()
  new MobileActiveButtons()
}, 1000)
